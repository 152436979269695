import { APP_VERSION, APP_BUILD_NR } from './version';
import { BACKEND_API_VERSION } from './backend_version';

export const APPSTORE_VERSION = APP_VERSION + (APP_BUILD_NR > 0 ? `(${APP_BUILD_NR})` : '');

export const NODE_ENV = process.env.NODE_ENV;

const ENVIRONMENT = process.env.VUE_APP_ENV || 'local';

const DEFAULT_SENTRY_DSN = 'https://a7043486f8b949faa4a1ef789ca2a8e6@sentry.isned.live/4';

let backendApiVersion = BACKEND_API_VERSION;
if (backendApiVersion.indexOf('YEAR') > -1) { // use current date if not specified
	const now = new Date();
	const year = now.getFullYear() - 2000;
	const month = now.getMonth() + 1;
	const day = now.getDate();
	backendApiVersion = backendApiVersion.replace('YEAR', year.toString()).replace('DAYMONTH', `${month < 10 ? '0' : ''}${month}${day < 10 ? '0' : ''}${day}`);
}

const localConfig = {
	ENVIRONMENT: 'local',
	FRONTEND_BASE_URL: 'http://localhost:8080',
	TRIQ_IDENTITY_PROVIDER_URL: 'https://id.dev.triq.ai',
	TRIQ_API_URL: 'https://api.dev.triq.ai',
	SENTRY_DSN: 'https://514c796fb44d4b29be77a516605e2e40@sentry.isned.live/2',
	SENTRY_VERSION: 'latest',
	SENTRY_TRACE_SAMPLE_RATE: 1.0,
	API_VERSION: backendApiVersion
};

const localBackendConfig = {
	ENVIRONMENT: 'local',
	FRONTEND_BASE_URL: 'http://localhost:8080',
	TRIQ_IDENTITY_PROVIDER_URL: 'https://localhost:5381',
	TRIQ_API_URL: 'https://localhost:5181',
	SENTRY_DSN: 'https://514c796fb44d4b29be77a516605e2e40@sentry.isned.live/2',
	SENTRY_VERSION: 'latest',
	SENTRY_TRACE_SAMPLE_RATE: 1.0,
	API_VERSION: backendApiVersion
};

const developmentConfig = {
	ENVIRONMENT: 'development',
	FRONTEND_BASE_URL: 'https://app.dev.triq.ai',
	TRIQ_IDENTITY_PROVIDER_URL: 'https://id.dev.triq.ai',
	TRIQ_API_URL: 'https://api.dev.triq.ai',
	SENTRY_DSN: 'https://514c796fb44d4b29be77a516605e2e40@sentry.isned.live/2',
	SENTRY_VERSION: 'latest',
	SENTRY_TRACE_SAMPLE_RATE: 1.0,
	API_VERSION: backendApiVersion
};

const stagingConfig = {
	ENVIRONMENT: 'staging',
	FRONTEND_BASE_URL: 'https://app.stage.triq.ai',
	TRIQ_IDENTITY_PROVIDER_URL: 'https://id.stage.triq.ai',
	TRIQ_API_URL: 'https://api.stage.triq.ai',
	SENTRY_DSN: DEFAULT_SENTRY_DSN,
	SENTRY_VERSION: APPSTORE_VERSION,
	SENTRY_TRACE_SAMPLE_RATE: 0.3,
	API_VERSION: backendApiVersion
};

const betaConfig = {
	ENVIRONMENT: 'beta',
	FRONTEND_BASE_URL: 'https://app.beta.triq.ai',
	TRIQ_IDENTITY_PROVIDER_URL: 'https://id.beta.triq.ai',
	TRIQ_API_URL: 'https://api.beta.triq.ai',
	SENTRY_DSN: DEFAULT_SENTRY_DSN,
	SENTRY_VERSION: APPSTORE_VERSION,
	SENTRY_TRACE_SAMPLE_RATE: 0.3,
	API_VERSION: backendApiVersion
};

const productionConfig = {
	ENVIRONMENT: 'production',
	FRONTEND_BASE_URL: 'https://app.triq.ai',
	TRIQ_IDENTITY_PROVIDER_URL: 'https://id.triq.ai',
	TRIQ_API_URL: 'https://api.triq.ai',
	SENTRY_DSN: DEFAULT_SENTRY_DSN,
	SENTRY_VERSION: APPSTORE_VERSION,
	SENTRY_TRACE_SAMPLE_RATE: 0.3,
	API_VERSION: backendApiVersion
};

let envConfig;
if (ENVIRONMENT === 'local') {
	envConfig = localConfig;
} else if (ENVIRONMENT === 'local-backend') {
	envConfig = localBackendConfig;
} else if (ENVIRONMENT === 'development') {
	envConfig = developmentConfig;
} else if (ENVIRONMENT === 'staging') {
	envConfig = stagingConfig;
} else if (ENVIRONMENT === 'beta') {
	envConfig = betaConfig;
} else { // production
	envConfig = productionConfig;
}

export const FRONTEND_BASE_URL = envConfig.FRONTEND_BASE_URL;
export const TRIQ_IDENTITY_PROVIDER_URL = envConfig.TRIQ_IDENTITY_PROVIDER_URL;
export const TRIQ_API_URL = envConfig.TRIQ_API_URL;

export const config = envConfig;