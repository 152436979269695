<template>
    <ion-header>
        <ion-toolbar class="triq-app-header">
            <div slot="start">
                <ion-buttons>
                    <ion-button router-link="/app/dashboard">
                        <img src="/assets/img/TRIQ-Logo-White.png" alt="TRIQ Logo" height="16"/>
                    </ion-button>
                </ion-buttons>
            </div>
            <AppHeaderNav slot="end"></AppHeaderNav>
        </ion-toolbar>
    </ion-header>
</template>

<script>
    import { IonButton, IonHeader, IonToolbar, IonButtons } from '@ionic/vue';
    import AppHeaderNav from './AppHeaderNav';

    export default {
        components: {
            IonButton, IonHeader, IonToolbar, IonButtons, AppHeaderNav
        }
    };
</script>