import axios from 'axios';
import { TRIQ_API_URL } from '../config';

const API_ENDPOINT_PERFORMANCE_TEST_PRE_VIEW = '/api/v1/app/performance-test/pre-view';
const API_ENDPOINT_PERFORMANCE_TEST_POST_VIEW = '/api/v1/app/performance-test/post-view';
const API_ENDPOINT_PERFORMANCE_TEST_CONFIRM = '/api/v1/app/performance-test/confirm';


export default {
    namespaced: true,

    actions: {
        getPreView({ dispatch }, { sessionId }) {
            return dispatch('auth/request', config => {
                config.params = { sessionId };

                return axios.get(TRIQ_API_URL + API_ENDPOINT_PERFORMANCE_TEST_PRE_VIEW, config);
            }, { root: true });
        },

        getPostView({ dispatch }, { sessionId }) {
            return dispatch('auth/request', config => {
                config.params = { sessionId };

                return axios.get(TRIQ_API_URL + API_ENDPOINT_PERFORMANCE_TEST_POST_VIEW, config);
            }, { root: true });
        },

        confirm({ dispatch }, { workoutId, confirm }) {
            return dispatch('auth/request', config => {
                config.params = { workoutId, confirm };

                return axios.put(TRIQ_API_URL + API_ENDPOINT_PERFORMANCE_TEST_CONFIRM, {}, config);
            }, { root: true });
        }
    }
};