export default {
    namespaced: true,

    state: {
        errors: {
            connectionError: false
        }
    },

    mutations: {
        async setConnectionError(state, status) {
            state.errors.connectionError = status;
        },

        async resetErrors(state) {
            state.errors.connectionError = false;
        }
    }
};