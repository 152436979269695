import { o } from 'odata';
import { TRIQ_API_URL } from '../config';
import axios from 'axios';

const API_ENDPOINT_HISTORY_DASHBOARD = '/api/v1/history/history-dashboard';
const API_ENDPOINT_HISTORY_ACTIVITY_DASHBOARD = '/api/v1/history/activity-dashboard';
const API_ENDPOINT_HISTORY_SPORT_SPLIT = '/api/v1/history/sport-split';
const API_ENDPOINT_HISTORY_COMPLETED_WORKOUTS = '/api/v1/history/completed-workouts';
const API_ENDPOINT_HISTORY_COMPLETION_RATE = '/api/v1/history/completion-rate';
const API_ENDPOINT_HISTORY_PERFORMANCE_TESTS = '/api/v1/history/performance-tests';

const API_ENDPOINT_COMPLETED_SESSION = '/api/v1/odata/completedsession/:key';
const API_ENDPOINT_COMPLETED_WORKOUT = '/api/v1/odata/completedworkout/:key';

const API_ENDPOINT_SESSION_FEEDBACK_OPTIONS = '/api/v1/app/workout/feedback-options';

const API_ENDPOINT_PLANNED_SESSIONS = '/api/v1/odata/plannedsession';
const API_ENDPOINT_PLANNED_SESSION = '/api/v1/odata/plannedsession/:key';

const API_ENDPOINT_PLANNED_WORKOUT_UPDATE = '/api/v1/odata/plannedworkout/:key';
const API_ENDPOINT_PLANNED_SESSION_UPDATE = '/api/v1/odata/plannedsession/:key';

const API_ENDPOINT_PLANNED_SESSIONS_GENERATE = '/api/v1/test/dummy/data/generate-plan';

const API_ENDPOINT_HISTORY_CHECKIN_LATEST_MEASUREMENT = '/api/v1/app/check-in/latest-measurement';

const API_ENDPOINT_FORECAST = '/api/v1/app/outlook';

export default {
    namespaced: true,

    actions: {
        getDashboard({ dispatch }, params) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_HISTORY_DASHBOARD)
                    .query(params)
            }, { root: true });
        },

        getActivityDashboard({ dispatch }, params) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_HISTORY_ACTIVITY_DASHBOARD)
                    .query(params)
            }, {root: true});
        },

        getSportSplit({ dispatch }, params) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_HISTORY_SPORT_SPLIT)
                    .query(params)
            }, { root: true });
        },

        getCompletedWorkouts({ dispatch }, params) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_HISTORY_COMPLETED_WORKOUTS)
                    .query(params)
            }, { root: true });
        },

        getCompletionRate({ dispatch }, params) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_HISTORY_COMPLETION_RATE)
                    .query(params)
            }, { root: true });
        },

        getPerformanceTests({ dispatch }, params) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_HISTORY_PERFORMANCE_TESTS)
                    .query(params)
            }, { root: true });
        },

        getCompletedWorkout({ dispatch }, { key, params }) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_COMPLETED_SESSION.replace(':key', key))
                    .query(params)
            }, { root: true });
        },

        updateCompletedWorkout({ dispatch }, { key, params, data, action = null }) {
            return dispatch('auth/request', {action: action, callback: config => {
                return o(TRIQ_API_URL, config)
                    .patch(API_ENDPOINT_COMPLETED_SESSION.replace(':key', key), data)
                    .query(params)
            }}, { root: true });
        },

        getSessionFeedbackOptions({ dispatch }) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_SESSION_FEEDBACK_OPTIONS)
                    .query()
            }, { root: true });
        },

        getPlannedSessions({ dispatch }, params) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_PLANNED_SESSIONS)
                    .query(params)

            }, { root: true });
        },

        getPlannedSession({ dispatch }, { key, params }) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_PLANNED_SESSION.replace(':key', key))
                    .query(params)
            }, { root: true });
        },

        generateDummyPlan({ dispatch }) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_PLANNED_SESSIONS_GENERATE)
                    .query()
            }, { root: true });
        },

        updatePlannedWorkout({ dispatch }, { plannedWorkoutId, data }) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .patch(API_ENDPOINT_PLANNED_WORKOUT_UPDATE.replace(':key', plannedWorkoutId), data)
                    .query()
            }, { root: true });
        },

        updatePlannedSession({ dispatch }, { plannedSessionId, data, action = null }) {
            return dispatch('auth/request', {action: action, callback: config => {
                return o(TRIQ_API_URL, config)
                    .patch(API_ENDPOINT_PLANNED_SESSION_UPDATE.replace(':key', plannedSessionId), data)
                    .query()
            }}, { root: true });
        },

        getCheckinLatestMeasurement({ dispatch }) {
            return dispatch('auth/request', config => {
                return axios.get(TRIQ_API_URL + API_ENDPOINT_HISTORY_CHECKIN_LATEST_MEASUREMENT, config);
            }, { root: true });
        },

        getForecast({ dispatch }, params) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_FORECAST)
                    .query(params)
            }, { root: true });
        },

        getCompletedMultisportWorkout({ dispatch }, { key, params }) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_COMPLETED_WORKOUT.replace(':key', key))
                    .query(params)
            }, { root: true });
        }
    }
};