import { o } from 'odata';
import { TRIQ_API_URL } from '../config';

const API_ENDPOINT_GOALS = '/api/v1/odata/athletegoal';
const API_ENDPOINT_GOAL_CREATE = '/api/v1/odata/athletegoal';
const API_ENDPOINT_GOAL = '/api/v1/odata/athletegoal/:raceGoalId';
const API_ENDPOINT_GOAL_UPDATE = '/api/v1/odata/athletegoal/:raceGoalId';
const API_ENDPOINT_GOAL_TARGET_TIME_OPTIONS = '/api/v1/app/onboarding/targettimeoptions/:athleteId';

const API_ENDPOINT_GOAL_FEASIBILITY = '/api/v1/app/onboarding/goalfeasibility/:athleteId'

export default {
    namespaced: true,

    state: {
        raceTypeOptions: ['Sprint', 'Olympic', 'HalfIronman', 'Ironman'],
        topographyOptions: [null, 'Hilly', 'Flat'],
    },

    actions: {
        getTargetTimeOptions({ dispatch }, { athlete_id }) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_GOAL_TARGET_TIME_OPTIONS.replace(':athleteId', athlete_id))
                    .query();
            }, { root: true });
        },

        createGoal({ dispatch }, { data, action = null }) {
            return dispatch('auth/request', {action: action, callback: config => {
                config.headers['Content-Type'] = "application/json";

                return o(TRIQ_API_URL, config)
                    .post(API_ENDPOINT_GOAL_CREATE, data)
                    .query();
            }}, { root: true });
        },

        updateGoal({ dispatch }, { id, data, action }) {
            return dispatch('auth/request', {action: action, callback: config => {
                config.headers['Content-Type'] = "application/json";

                return o(TRIQ_API_URL, config)
                    .patch(API_ENDPOINT_GOAL_UPDATE.replace(':raceGoalId', id), data)
                    .query();
            }}, { root: true });
        },

        getGoals({ dispatch }) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_GOALS)
                    .query();
                // .query({
                //     $filter: `AthleteId eq ${athlete_id}`
                // });
            }, { root: true });
        },

        getGoalFeasibility({ dispatch }, { athlete_id }) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_GOAL_FEASIBILITY.replace(':athleteId', athlete_id))
                    .query();
            }, { root: true });
        },

        getGoal({ dispatch }, id) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_GOAL.replace(':raceGoalId', id))
                    .query();
            }, { root: true });
        },

        getGoalPlanUpdateNeeded({ dispatch }, id) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_GOAL.replace(':raceGoalId', id))
                    .query({
                        $select: 'NeedsPlanGeneration'
                    });
            }, { root: true });
        }
    }
};