export default {
    namespaced: true,

    state: {
        showSuccessCheckmarkToast: false
    },

    mutations: {
        setShowSuccessCheckmarkToast(state, show) {
            state.showSuccessCheckmarkToast = show;
        }
    },

    actions: {
        showSuccessCheckmarkToast({ commit }) {
            setTimeout(() => {
                commit('setShowSuccessCheckmarkToast', true);
    
                setTimeout(() => {
                    commit('setShowSuccessCheckmarkToast', false);
                }, 1500);
            }, 500);
        }
    }
};