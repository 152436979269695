function isBlacklisted(email) {
    // Blacklist Apple's private relay email addresses, because email forwarding can be disabled
    if (email.endsWith('@privaterelay.appleid.com')) {
        return true;
    }

    return false;
}

export {
    isBlacklisted
};