export default {
    namespaced: true,

    state: {
        showPerformanceStats: false
    },

    mutations: {
        togglePerformanceStats(state: any) {
            state.showPerformanceStats = !state.showPerformanceStats;
        }
    }
};