<template>
    <div class="toast-container">
        <Transition name="fade">
            <SuccessCheckmarkToast v-if="$store.state.toast.showSuccessCheckmarkToast" />
        </Transition>
    </div>
</template>

<script>
    import SuccessCheckmarkToast from './SuccessCheckmarkToast.vue';

    export default {
        components: {
            SuccessCheckmarkToast
        }
    };
</script>