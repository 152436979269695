import axios from 'axios';
import { modalController } from '@ionic/vue';
import IncompatibleAppVersionModal from '@/components/Compatibilty/IncompatibleAppVersionModal.vue';

import { TRIQ_API_URL } from '../config';

const API_ENDPOINT_COMPATIBILITY = '/api/v1/app/compatibility';

export default {
    namespaced: true,

    state: {
        
    },

    actions: {
        fetchCompatibility({ dispatch }) {
            return dispatch('auth/request', config => {
                return axios.get(TRIQ_API_URL + API_ENDPOINT_COMPATIBILITY, config);
            }, { root: true });
        },

        async checkCompatibility({ dispatch }) {
            try {
                console.debug('[Compatibility] Checking compatibility ... ');
                const response = await dispatch('fetchCompatibility');
                console.debug('[Compatibility] Compatibility check response', response.data);
                if (response && response.data) {
                    if (response.data.compatibility != 'NoUserActionNeeded') {
                        const dismissable = ['UpdateAvailable', 'UpdateRecommended', 'UpdateUrgent'].indexOf(response.data.compatibility) !== -1;
                        const modal = await modalController.create({
                            component: IncompatibleAppVersionModal,
                            cssClass: 'compatibility-modal',
                            backdropDismiss: dismissable,
                            componentProps: {
                                compatibility: response.data
                            }
                        });
                        await modal.present();
                    }
                }
            } catch (err) {
                console.error('[Compatibility] Compatibility check failed', err);
            }
        },

    }
};
