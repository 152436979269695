import { TRIQ_API_URL } from '../config';
import axios from 'axios';

const API_ENDPOINT_UI_EVENT = '/api/v1/app/ui-event';

export default {
    namespaced: true,

    state: {

    },

    actions: {
        storeUiEvent({ dispatch }, payload) {
            return dispatch('auth/request', async config => {
                await axios.post(TRIQ_API_URL + API_ENDPOINT_UI_EVENT, payload, config);
            }, { root: true });
        },

        trackPageView({ dispatch }, { path, meta = null }) {
            return dispatch('storeUiEvent', {
                EventType: 'page.view',
                Page: path,
                MetaData: meta
            });
        },

        trackButtonClick({ dispatch }, { action, path = null, meta = null}) {
            return dispatch('storeUiEvent', {
                EventType: 'button.click',
                Action: action,
                Page: path,
                MetaData: meta
            });
        },

        trackDialogView({ dispatch }, { action, path = null, meta = null }) {
            return dispatch('storeUiEvent', {
                EventType: 'dialog.view',
                Action: action,
                Page: path,
                MetaData: meta
            });
        }
    }
};