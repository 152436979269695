import * as moment from 'moment';

/**
 *  input:
 *      'YYYY-MM-DDTHH:MM:SSZ'
 *  output:
 *      'YYYY-MM-DD'
 **/
function convertToDate(date) {
    if (date) {
        return date.split('T')[0];
    }

    return null;
}

/**
 *  input:
 *      'YYYY-MM-DD'
 *  output:
 *      'YYYY-MM-DDTHH:MM:SSZ'
 **/
function convertFromDate(date) {
    if (date) {
        if (date.length > 10) {
            date = date.substring(0, 10);
        }

        return date + 'T00:00:00Z';
    }

    return null;
}

/**
 *  input:
 *      moment duration obj: f.e. -> moment.duration(3, 'minutes')
 *  output:
 *      '3mins', '1h3mins', etc.
 **/
function formatMomentDurationAsTimeHoursAndMins(momentDuration) {
    const hoursInt = momentDuration.hours();
    const minutesInt = momentDuration.minutes();

    let minutesFormated = '';
    if (hoursInt > 0) {
        minutesFormated += hoursInt + 'h ';
    }

    if (minutesInt > 0) {
        minutesFormated += minutesInt + 'min';
    }

    return minutesFormated;
}

function formatDuration(momentDuration, asHTML = false, withUnit = true, unitTemplate = '<small class="body_2 fg-white-50" style="font-weight: normal">{unit}</small>') {
    if (momentDuration.asHours() >= 1) {
        const str = Math.floor(momentDuration.asHours()).toString() + ':' + (momentDuration.minutes()).toString().padStart(2, '0'); // + ':' + (momentDuration.seconds()).toString().padStart(2, '0');

        if (withUnit) {
            if (asHTML) {
                return str + ' ' + unitTemplate.replace('{unit}', 'h');
            }
    
            return str + ' h';
        }

        return str;
    }

    const str = Math.floor(momentDuration.asMinutes()).toString() + ':' + (momentDuration.seconds()).toString().padStart(2, '0');
    if (withUnit) {
        if (asHTML) {
            return str + ' ' + unitTemplate.replace('{unit}', 'min');
        }
        
        return str + ' min';
    }

    return str;
}

function formatDurationWithoutSeconds(momentDuration) {
    return Math.floor(momentDuration.asHours()).toString() + ':' + (momentDuration.minutes()).toString().padStart(2, '0');
}

function formatDurationWithMinutesAndSeconds(momentDuration) {
    // return Math.floor(momentDuration.asMinutes()).toString() + ':' + (momentDuration.seconds()).toString().padStart(2, '0');

    const minutes = Math.floor(momentDuration.asMinutes());

    const seconds = Math.round(momentDuration.asSeconds() - (momentDuration.minutes() * 60));

    return minutes + ':' + seconds.toString().padStart(2, '0');
}

function convertDurationWithMinutesAndSecondsToSeconds(str) {
    return moment.duration('00:' + str).asSeconds();
}

function convertDurationWithMinutesAndSecondsToMinutes(str) {
    return moment.duration('00:' + str).asMinutes();
}

function formatDurationAsTimeInHours(momentDuration, withUnit = true) {
    let str = Math.floor(momentDuration.asHours()).toString() + ':' + (momentDuration.minutes()).toString().padStart(2, '0');

    if (withUnit) {
        str += ' h';
    }

    return str;
}

function formatDurationWithSeconds(momentDuration) {
    // if memomentDuration is less than 1 hour
    if (momentDuration.asHours() < 1) {
        return Math.floor(momentDuration.asMinutes()).toString() + ':' + (momentDuration.seconds()).toString().padStart(2, '0') + ' min';
    }

    return Math.floor(momentDuration.asHours()).toString() + ':' + (momentDuration.minutes()).toString().padStart(2, '0') + ':' + (momentDuration.seconds()).toString().padStart(2, '0') + ' h';
}

function formatMinutesAsTime(minutes) {
    return Math.floor(minutes / 60).toString() + ':' + (minutes % 60).toString().padStart(2, '0');
}

export { 
    convertToDate,
    convertFromDate,
    formatMomentDurationAsTimeHoursAndMins,
    formatDuration,
    formatDurationWithoutSeconds,
    formatDurationWithMinutesAndSeconds,
    convertDurationWithMinutesAndSecondsToSeconds,
    convertDurationWithMinutesAndSecondsToMinutes,
    formatDurationAsTimeInHours,
    formatDurationWithSeconds,
    formatMinutesAsTime
};