import { o } from 'odata';
import { TRIQ_API_URL } from '../config';

const API_ENDPOINT_ATHLETE_PROFILE = '/api/v1/odata/AthleteProfile/:key';
const API_ENDPOINT_ATHLETE_PROFILE_UPDATE = '/api/v1/odata/AthleteProfile/:key';

const API_ENDPOINT_ATHLETE_PROFILE_TRAINING_VOLUME_HINT = '/api/v1/app/onboarding/trainingvolumehint/:key';
const API_ENDPOINT_ATHLETE_PROFILE_TRAINING_VOLUME_VALIDATION = '/api/v1/app/training-volume/validate';
const API_ENDPOINT_AVAILABILITY = '/api/v1/app/availability/:key';
const API_ENDPOINT_AVAILABILITY_UPDATE = '/api/v1/app/availability/:key';
const API_ENDPOINT_AVAILABILITY_VALIDATE = '/api/v1/app/availability/validate/:key';
const API_ENDPOINT_ENDURANCE_SPORTS_OPTIONS = '/api/v1/app/onboarding/enduranceSportOptions';

const API_ENDPOINT_TIMEZONE_UPDATE = '/api/v1/app/update-time-zone';

export default {
    namespaced: true,

    state: {
        genderOptions: ['Male', 'Female'],
        preferredUnitSystemOptions: ['Metric', 'Imperial'],
        intentionOptions: ['Beginner', 'RampUp', 'NextLevelRace', 'Optimizing'],
        mafOptions: ['Rehabilitation', 'Stagnating', 'ConsistentTraining', 'Competitive'],

        cyclingEnduranceMeasurementTypeOptions: ['HeartRate', 'Power'],
        cyclingIntervalMeasurementTypeOptions: ['HeartRate', 'Power'],
        cyclingIndoorMeasurementTypeOptions: ['HeartRate', 'Power'],
        // runningEnduranceMeasurementTypeOptions: ['HeartRate', 'Power', 'Speed','Pace'],
        // runningIntervalMeasurementTypeOptions: ['Pace', 'Speed', 'HeartRate','Power'],
        runningEnduranceMeasurementTypeOptions: ['HeartRate', 'Pace'],
        runningIntervalMeasurementTypeOptions: ['HeartRate', 'Pace'],

        heightRegex: /^\d{2,3}([.,]\d)?$/,
        heightMinMetric: 60,
        heightMaxMetric: 250,
        heightStepMetric: 1,
        heightUnitMetric: 'cm',
        heightMinImperial: 20,
        heightMaxImperial: 95,
        heightStepImperial: 1,
        heightUnitImperial: 'in',

        weightRegex: /^\d{2,3}([.,]\d)?$/,
        weightMinMetric: 30,
        weightMaxMetric: 150,
        weightStepMetric: 1,
        weightUnitMetric: 'kg',
        weightMinImperial: 66,
        weightMaxImperial: 330,
        weightStepImperial: 1,
        weightUnitImperial: 'lbs',

        firstnameRegex: /^[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]*$/,
        lastnameRegex: /^[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]*$/,
        usernameRegex: /^[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]*$/,

        athleteProfile: null
    },

    mutations: {
        setAthleteProfile(state, profile) {
            state.athleteProfile = profile;
        }
    },

    actions: {
        async getCachedProfile({ state, commit, dispatch, rootState }) {
            if (!state.athleteProfile) {
                const athleteId = rootState.api.identity.athleteId;
                commit('setAthleteProfile', await dispatch('getProfile', { athlete_id: athleteId }));
            }

            return state.athleteProfile;
        },

        getProfile({ dispatch }, { athlete_id, queryParams }) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_ATHLETE_PROFILE.replace(':key', athlete_id))
                    .query(queryParams);
            }, {root: true});
        },

        updateProfile({ dispatch }, { athlete_id, data, action = null }) {
            return dispatch('auth/request', {action: action, callback: config => {
                config.headers['Content-Type'] = "application/json";

                return o(TRIQ_API_URL, config)
                    .patch(API_ENDPOINT_ATHLETE_PROFILE_UPDATE.replace(':key', athlete_id), data)
                    .query();
            }}, {root: true});
        },

        getTrainingVolumeHint({ dispatch }, { athlete_id }) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_ATHLETE_PROFILE_TRAINING_VOLUME_HINT.replace(':key', athlete_id))
                    .query();
            }, {root: true});
        },

        getTrainingVolumeValidation({ dispatch }) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_ATHLETE_PROFILE_TRAINING_VOLUME_VALIDATION)
                    .query();
            }, { root: true });
        },

        getAvailability({ dispatch }, { athlete_id }) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_AVAILABILITY.replace(':key', athlete_id))
                    .query();
            }, {root: true});
        },

        validateAvailability({ dispatch }, { athlete_id, data }) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .post(API_ENDPOINT_AVAILABILITY_VALIDATE.replace(':key', athlete_id), data)
                    .query();
            }, {root: true});
        },

        updateAvailability({ dispatch }, { athlete_id, data, action = null }) {
            return dispatch('auth/request', {action: action, callback: config => {
                return o(TRIQ_API_URL, config)
                    .post(API_ENDPOINT_AVAILABILITY_UPDATE.replace(':key', athlete_id), data)
                    .query();
            }}, {root: true});
        },

        getEnduranceSportsOptions({ dispatch }) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_ENDURANCE_SPORTS_OPTIONS)
                    .query();
            }, {root: true});
        },

        updateTimezone({ dispatch }, { data }) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .post(API_ENDPOINT_TIMEZONE_UPDATE, data)
                    .query();
            }, {root: true});
        }
    }
};