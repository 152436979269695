import { Network } from '@capacitor/network';

export default {
    namespaced: true,

    state: {
        
    },

    mutations: {
        
    },

    actions: {
        getStatus() {
            return Network.getStatus();
        }
    }
};