import { toastController } from '@ionic/vue';

import { alertCircle, informationCircle, checkmarkCircle, checkmarkCircleOutline } from 'ionicons/icons';

import store from '@/store';

async function toastError(message) {
    const toast = await toastController.create({
        message: message,
        duration: 3000,
        position: 'bottom',
        color: 'danger',
        icon: alertCircle
    });

    toast.present();
}

async function toastInfo(message) {
    const toast = await toastController.create({
        message: message,
        duration: 3000,
        position: 'bottom',
        color: 'primary',
        icon: informationCircle
    });

    toast.present();
}

async function toastSuccess(message) {
    const toast = await toastController.create({
        message: message,
        duration: 3000,
        position: 'top',
        color: 'success',
        icon: checkmarkCircle
    });

    toast.present();
}

async function toastSuccessCheckmark() {
    store.dispatch('toast/showSuccessCheckmarkToast');
}

export {
    toastError,
    toastInfo,
    toastSuccess,
    toastSuccessCheckmark
};