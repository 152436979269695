import { PushNotifications } from '@capacitor/push-notifications';
import { getDeviceId } from '@/library/push-notifications';

import store from '../store';

import { toastError } from '../library/toast';

import { isBlacklisted } from '../library/email';

export default {
    async before(to, from) {
        if (to.meta.requiresAuth) {
            console.debug('Page requires auth', to);
            const isLoggedIn = await store.dispatch('auth/isLoggedIn');
            if (!isLoggedIn) {
                console.debug('Trying to refresh AccessToken');

                try {
                    await store.dispatch('auth/refreshAccessToken');
                } catch (err) {
                    console.error('Failed to refresh access token', err);
                }

                const isLoggedIn = await store.dispatch('auth/isLoggedIn');
                if (!isLoggedIn) {
                    console.debug('Permission denied. Redirecting to login ...');
                    store.dispatch('auth/logout', true);
                    return false;
                }
            }

            // fetch identity
            try {
                const response = await store.dispatch('auth/preAuth');
                console.debug('Fetched identity', response);

                await this.checkPermissions();

                if (store.state.api.identity && store.state.api.identity.profile) {
                    if (store.state.api.identity.profile.onBoardingStatus == 'OnBoardingCompleted') {
                        // Check if we need to forward to performance test threshold confirmation page
                        if (store.state.api.identity.latestPerformanceTestId && to.path.indexOf('/app/workout/performance-test') === -1) {
                            return {
                                path: '/app/workout/performance-test/' + store.state.api.identity.latestPerformanceTestId + '/threshold'
                            }
                        }

                        // Check if we need to to forward to sean ellis test
                        if (store.state.api.identity.profile.showSeanEllisTest && to.path.indexOf('/app/sean-ellis-test') === -1 && to.path.indexOf('/app/forecast') === 0) {
                            return {
                                path: '/app/sean-ellis-test'
                            }
                        }
                    }
                }

                return this.redirectOnboarding(to);
            } catch (err) {
                console.error('Could not fetch identity - logging out', err);
                if (err.code == 'ERR_NETWORK') {
                    toastError('Connection problem! Please check your internet connection.');
                    store.commit('app/setConnectionError', true);
                    if (from) {
                        return from;
                    }
                    return false;
                } else if (err.response && err.response.status != 401) {
                    toastError('Connection problem! Please check your internet connection.');
                    store.commit('app/setConnectionError', true);
                    console.error('Fetching identity failed', err.response);
                    if (from) {
                        return from;
                    }
                    return false;
                }
                store.dispatch('auth/logout', true);
            }
        }
    },

    async checkPermissions() {
        const currentUser = await store.dispatch('auth/getCurrentUser');
        if (currentUser) {
            try {
                const currentPermission = await PushNotifications.checkPermissions();
                if (currentPermission.receive === 'prompt') {
                    const result = await PushNotifications.requestPermissions();

                    if (result.receive === 'granted') {
                        PushNotifications.register();
                    }
                }

                await PushNotifications.checkPermissions().then(async currentPermission => {
                    const hasPermission = currentPermission.receive === 'granted';
                    const deviceId = await getDeviceId();
                    console.debug('Updating device push permissions', {
                        deviceId: deviceId,
                        hasPermission: hasPermission
                    });

                    if (deviceId) {
                        store.dispatch('api/updateDevicePermissions', {
                            deviceId: deviceId,
                            notificationPermission: hasPermission
                        });
                    }
                });
            } catch (err) {
                console.info('Push notifications are not supported on this device', err);
            }
        }
    },

    async redirectOnboarding(to) {
        if (store.state.api.identity) {
            if (store.state.api.identity.profile && store.state.api.identity.profile.onBoardingStatus) {
                // let expectedRoute = null;
                console.log('Refresh onboarding', to);
                // TODO: redirect to correct onboarding page (depending on the given onBoardingStatus)


                console.log('Current onboarding status', store.state.api.identity.profile.onBoardingStatus);
                if (store.state.api.identity.profile.onBoardingStatus != 'OnBoardingCompleted') {
                    if (to.path.indexOf('/onboarding/') === -1) {
                        const currentUser = await store.dispatch('auth/getCurrentUser');
                        if (currentUser) {
                            if (isBlacklisted(currentUser.email)) {
                                return {
                                    path: '/onboarding/email'
                                };
                            }
                        }

                        return {
                            path: '/onboarding/connect'
                        };
                    }
                }

                // switch (store.state.api.identity.profile.onBoardingStatus) {
                //     case 'NotStarted':
                //         store.dispatch('athlete/updateProfile', { athlete_id: store.state.api.identity.athleteId, data: {onBoardingStatus: 'RegistrationCompleted'} });


                //         expectedRoute = '/onboarding/connect';
                //         if (to.path.indexOf('/onboarding/') === -1) {
                //             return {path: expectedRoute};
                //         } 
                //         break;

                //     case 'SportsDataProviderConnectionCompleted':

                //         expectedRoute = '/onboarding/intro';
                //         if (to.path.indexOf('/onboarding/') === -1) {
                //             return {path: expectedRoute};
                //         } 
                //         break;                        
                // }
            }
        }
    }
}