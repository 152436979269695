import { createStore } from "vuex";
import api from './store/api';
import app from './store/app';
import auth from './store/auth';
import athlete from './store/athlete';
import compatibility from './store/compatibility';
import country from './store/country';
import goal from './store/goal';
import dailyCheckin from './store/dailyCheckin';
import history from './store/history';
import network from './store/network';
import notification from './store/notification';
import performanceTest from './store/performanceTest';
import performanceStats from './store/performanceStats';
import roadmap from './store/roadmap';
import seanEllis from './store/seanEllis';
import toast from './store/toast';
import tracking from './store/tracking';
import trainingplan from './store/trainingplan';

export default createStore({
    modules: {
        api,
        app,
        auth,
        athlete,
        compatibility,
        country,
        goal,
        dailyCheckin,
        history,
        network,
        notification,
        performanceTest,
        performanceStats,
        roadmap,
        seanEllis,
        toast,
        tracking,
        trainingplan
    },
})
