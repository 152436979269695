<template>
    <ion-buttons class="triq-app-header-nav">
        <ion-button href="https://www.triq.ai/manual" target="_blank">
            <ion-icon :icon="informationCircleOutline"></ion-icon>
        </ion-button>
        <ion-button router-link="/app/settings">
            <ion-icon :icon="optionsOutline"></ion-icon>
        </ion-button>
        <ion-button router-link="/app/notifications">
            <span v-if="hasUnreadNotifications" class="triq-notification-unread-indicator"></span>
            <ion-icon :icon="notificationsOutline"></ion-icon>
        </ion-button>
    </ion-buttons>
</template>

<script>
    import { notificationsOutline, optionsOutline, ellipse, informationCircleOutline } from 'ionicons/icons';
    import { IonButton, IonIcon, IonButtons } from '@ionic/vue';

    export default {
        components: {
            IonButton, IonIcon, IonButtons
        },

        setup() {
            return {
                notificationsOutline, optionsOutline, ellipse, informationCircleOutline
            };
        },

        computed: {
            hasUnreadNotifications() {
                if (this.$store.state.api.identity) {
                    return this.$store.state.api.identity.unreadNotifications > 0;
                }

                return false;
            }
        }
    };
</script>