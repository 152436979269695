<template>
    <ion-tab-bar class="triq-app-navbar" v-if="showAppBar">
        <ion-tab-button tab="plan" href="/app/dashboard">
            <img src="/assets/img/nav/home.svg" class="nav-icon" />
            <ion-label>{{ $t('nav.items.plan_btn') }}</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="forecast" href="/app/forecast">
            <img src="/assets/img/nav/forecast.svg" class="nav-icon" />
            <ion-label>{{ $t('nav.items.forecast_btn') }}</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="roadmap" href="/app/roadmap">
             <img src="/assets/img/nav/roadmap.svg" class="nav-icon" />
            <ion-label>{{ $t('nav.items.roadmap_btn') }}</ion-label>
        </ion-tab-button>
        <ion-tab-button href="/app/history" :class="{'tab-selected': isHistoryTabActive}">
            <img src="/assets/img/nav/history.svg" class="nav-icon" />
            <ion-label>{{ $t('nav.items.history_btn') }}</ion-label>
        </ion-tab-button>
    </ion-tab-bar>
</template>

<script>
    import { IonTabBar, IonTabButton, IonLabel } from '@ionic/vue';

    export default {
        components: {
            IonTabBar, IonTabButton, IonLabel
        },

        computed: {
            isHistoryTabActive() {
                return this.$route.path.indexOf('/app/history') === 0 && this.$route.path.indexOf('/app/history/') === -1;
            },

            isPlanTabActive() {
                return this.$route.path.indexOf('/app/dashboard') === 0;
            },

            isForecastTabActive() {
                return this.$route.path.indexOf('/app/forecast') === 0 && this.$route.path.indexOf('/app/forecast/') === -1;
            },

            isRoadmapTabActive() {
                return this.$route.path.indexOf('/app/roadmap') === 0;
            },

            showAppBar() {
                return this.isHistoryTabActive || this.isPlanTabActive || this.isForecastTabActive || this.isRoadmapTabActive;
            }
        }
    };
</script>