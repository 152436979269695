import store from '../store';

export default {
    // async before(to, from) {
    //     try {
    //         const payload = {
    //             path: to.path || '/',
    //             meta: {
    //                 from: from.path || '/'
    //             }
    //         };
    
    //         await store.dispatch('tracking/trackPageView', payload);
    //     } catch (err) {
    //         console.error('Failed to track page view', err);
    //     }
    // }

    before(to, from) {
        const payload = {
            path: to.path || '/',
            meta: {
                from: from.path || '/'
            }
        };

        store.dispatch('tracking/trackPageView', payload).catch(err => {
            console.error('Failed to track page view', err);
        });
    }
}