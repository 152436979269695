<template>
    <div class="triq-modal page-padding ion-padding">
        <div>
            <ion-button v-if="showCloseBtn" color="transparent" class="close-btn" @click="closeModal">
                <ion-icon :icon="close"></ion-icon>
            </ion-button>

            <div class="ion-padding ion-margin-bottom">
                <div class="ion-text-center">
                    <ion-icon :icon="warningOutline" class="text-warning" style="font-size: 3.3rem;"></ion-icon>
                </div>
                <h1 class="header_2 ion-text-center">{{ $t('compatibility.incompatible_app_version_modal.' + compatibility.compatibility + '.title') }}</h1>
                <div v-if="compatibility.userHint" class="body_3 ion-text-center text-grey" v-html="compatibility.userHint"></div>
                <div v-else class="body_3 ion-text-center text-grey" v-html="$t('compatibility.incompatible_app_version_modal.' + compatibility.compatibility + '.description')"></div>
            </div>
        </div>
        <ion-footer>
            <ion-button expand="block" class="cta cta-highlight" @click="update">{{ $t('compatibility.incompatible_app_version_modal.' + compatibility.compatibility + '.update_btn') }}</ion-button>
        </ion-footer>
    </div>
</template>

<script>
import { IonButton, modalController, IonIcon, IonFooter } from '@ionic/vue'
import { close, warningOutline } from 'ionicons/icons';

import BaseModal from '@/components/Modal/BaseModal.vue';

export default {
    extends: BaseModal,

    components: {
        IonButton, IonIcon, IonFooter
    },

    props: {
        compatibility: {
            type: Object
        }
    },

    setup() {
        return {
            close,
            warningOutline
        };
    },

    computed: {
        showCloseBtn() {
            return ['UpdateAvailable', 'UpdateRecommended', 'UpdateUrgent'].indexOf(this.compatibility.compatibility) != -1;
        }
    },

    data() {
        return {
            dialogName: 'compatibility.incompatible_app_version',
            dialogMeta: {
                compatibility: this.compatibility
            }
        };
    },

    methods: {
        closeModal() {
            modalController.dismiss();
        },

        update() {
            this.$store.dispatch('tracking/trackButtonClick', {
                action: 'compatibility.incompatible_app_version.update',
                path: this.$route.path
            });

            location.href = 'https://apps.apple.com/at/app/triq-triathlon-training-plan/id6446361544';
        }
    }
};
</script>