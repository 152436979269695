import { o } from 'odata';
import { TRIQ_API_URL } from '../config';

const API_ENDPOINT_COUNTRIES = '/api/v1/app/countries';

export default {
    namespaced: true,

    state: {
        countries: []
    },

    mutations: {
        setCountries(state, countries) {
            state.countries = countries;
        }
    },

    getters: {
        getCountryByCode(state) {
            return countryCode => {
                let country;
                for (let i = 0; i < state.countries.length; i++) {
                    country = state.countries[i];

                    if (country.twoLetterCode === countryCode || country.threeLetterCode === countryCode) {
                        return country;
                    }
                }

                return null;
            };
        }
    },

    actions: {
        fetchCountries({ commit, dispatch }) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_COUNTRIES)
                    .query().then(data => {
                        commit('setCountries', data);

                        return data;
                    });
            }, {root: true});
        }
    }
};