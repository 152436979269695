<template>
    <div v-if="performanceStats" class="performance-stats-container" style="position: absolute; width: 100%; top: 0; background: red; color: white; padding: 5px 12px; font-size: 5pt; z-index: 10000;">
        <table width="100%">
            <tr v-if="performanceStats['app.startup']" class="fg-white-50">
                <td>App Startup:</td><td align="right">{{ performanceStats['app.startup'].toFixed(1) }}ms</td>
            </tr>
            <tr v-if="performanceStats['app.routing.middleware']">
                <td>Middleware:</td><td align="right">{{ performanceStats['app.routing.middleware'].toFixed(1) }}ms</td>
            </tr>
            <tr v-if="performanceStats['app.routing.page']">
                <td>Page:</td><td align="right">{{ performanceStats['app.routing.page'].toFixed(1) }}ms</td>
            </tr>
            <tr v-if="performanceStats['app.api.data']">
                <td>Fetch API:</td><td align="right">{{ performanceStats['app.api.data'].toFixed(1) }}ms</td>
            </tr>
        </table>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                performanceStats: {}
            };
        },

        methods: {
            getPerformanceStats() {
                let result = {};

                const measurementKeys = [
                    'app.startup', 'app.routing.middleware', 'app.routing.page', 'app.api.data'
                ];

                for (let i = 0; i < measurementKeys.length; i++) {
                    try {
                        const key = measurementKeys[i];
                        const diff = performance.measure(key, key + '.start', key + '.end');
    
                        result[key] = diff.duration;
                    } catch (e) {
                        console.error('Failed to get performance stats', e);
                    }
                }

                performance.clearMarks();
                performance.clearMeasures();

                return result;
            },

            refresh() {
                this.performanceStats = this.getPerformanceStats();
            }
        },

        created() {
            // useIonViewDidEnter((event) => {
            //     this.refresh();
            // })
            // // register router listener and refresh() on route change
            // this.$router.afterEach((to, from) => {
            //     // wait until component has fully been rendered
            //     this.$nextTick(() => {
            //         setTimeout(() => {
            //             this.refresh();
            //         }, 50);
            //     });
            // });

            document.body.addEventListener('api.data.loaded', this.refresh);
        },

        mounted() {
            this.refresh();
        },

        beforeUnmount() {
            document.body.removeEventListener('api.data.loaded', this.refresh);
        }
    };
</script>