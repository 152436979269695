<script>
    export default {
        methods: {
            trackDialogView() {
                if (this.dialogName) {
                    this.$store.dispatch('tracking/trackDialogView', {
                        action: this.dialogName,
                        path: this.$route.path,
                        meta: this.dialogMeta ? this.dialogMeta : null
                    });
                }
            },

            init() {
                this.trackDialogView();
            }
        },

        created() {
            this.init();
        }
    };
</script>