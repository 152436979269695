import { o } from 'odata';
import { TRIQ_API_URL } from '../config';

const API_ENDPOINT_NOTIFICATIONS = '/api/v1/odata/notification';
const API_ENDPOINT_NOTIFICATION = '/api/v1/odata/notification/:id';
const API_ENDPOINT_NOTIFICATION_DELETE = '/api/v1/odata/notification/:id';

export default {
    namespaced: true,

    state: {
        
    },

    actions: {
        getNotifications({ dispatch }, queryParams) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_NOTIFICATIONS)
                    .query(queryParams);
            }, { root: true });
        },

        getNotification({ dispatch }, id) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_NOTIFICATION.replace(':id', id))
                    .query();
            }, { root: true });
        },

        deleteNotification({ dispatch }, id) {
            return dispatch('auth/request', {action: 'notification.delete', callback: config => {
                return o(TRIQ_API_URL, config)
                    .patch(API_ENDPOINT_NOTIFICATION_DELETE.replace(':id', id), { wasDeleted: true })
                    .query();
            }}, { root: true });
        },

        markAsRead({ dispatch }, id) {
            return dispatch('auth/request', {action: 'notification.read', callback: config => {
                return o(TRIQ_API_URL, config)
                    .patch(API_ENDPOINT_NOTIFICATION.replace(':id', id), { isRead: true })
                    .query();
            }}, { root: true });
        }
    }
};