import { o } from 'odata';
import { TRIQ_API_URL } from '../config';

const API_ENDPOINT_ATHLETE_DAILY_CHECKIN = '/api/v1/odata/AthleteDailyCheckin';
const API_ENDPOINT_ATHLETE_DAILY_CHECKIN_UPDATE = '/api/v1/odata/AthleteDailyCheckin/:id';
const API_ENDPOINT_ATHLETE_DAILY_CHECKIN_HISTORY = '/api/v1/app/check-in/history';

const API_ENDPOINT_ATHLETE_MUSCLE_FATIGUE_CREATE = '/api/v1/odata/athletemusclefatigue';

const API_ENDPOINT_CHECKIN_BRIEFING = '/api/v1/app/check-in/briefing';
const API_ENDPOINT_CHECKIN_BRIEFING_OPTIONS = '/api/v1/app/check-in/briefing/options';

export default {
    namespaced: true,

    state: {
        briefingEmojiMap: {
            fatigue: {
                VeryFresh: '😃',
                Fresh: '😊',
                Normal: '😐',
                MoreFatigued: '😒',
                VeryFatigued: '🥵'
            },
            stress: {
                VeryRelaxed: '😃',
                RelaxedAndEasy: '😊',
                NormalAndBalanced: '😐',
                FeelStressed: '😒',
                HighlyStressed: '🥵'
            },
            sleep: {
                VeryRestful: '😃',
                Good: '😊',
                Average: '😐',
                Poor: '😒',
                ExtremelyPoor: '🥵'
            },
            mood: {
                VeryPositive: '😃',
                GeneralGood: '😊',
                LessInterested: '😐',
                Snappiness: '😒',
                HighlyAnnoyed: '🥵'
            },
        }
    },

    actions: {
        get({ dispatch }, query = undefined) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_ATHLETE_DAILY_CHECKIN)
                    .query(query);
            }, {root: true});
        },

        post({ dispatch }, { data }) {
            return dispatch('auth/request', config => {
                config.headers['Content-Type'] = "application/json";

                return o(TRIQ_API_URL, config)
                    .post(API_ENDPOINT_ATHLETE_DAILY_CHECKIN, data)
                    .query();
            }, {root: true});
        },

        update({ dispatch }, { id, data, action = null }) {
            return dispatch('auth/request', {action: action, callback: config => {
                config.headers['Content-Type'] = "application/json";

                return o(TRIQ_API_URL, config)
                    .patch(API_ENDPOINT_ATHLETE_DAILY_CHECKIN_UPDATE.replace(':id', id), data)
                    .query();
            }}, {root: true});
        },

        getHistory({ dispatch }, { data }) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_ATHLETE_DAILY_CHECKIN_HISTORY)
                    .query(data);
            }, {root: true});
        },

        createMuscleFatigueFeedback({ dispatch }, data) {
            return dispatch('auth/request', config => {
                config.headers['Content-Type'] = "application/json";

                return o(TRIQ_API_URL, config)
                    .post(API_ENDPOINT_ATHLETE_MUSCLE_FATIGUE_CREATE, data)
                    .query();
            }, { root: true });
        },

        getBriefing({ dispatch }) {
            return dispatch('auth/request', config => {
                config.headers['Content-Type'] = "application/json";

                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_CHECKIN_BRIEFING)
                    .query();
            }, { root: true });
        },

        getBriefingOptions({ dispatch }) {
            return dispatch('auth/request', config => {
                config.headers['Content-Type'] = "application/json";

                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_CHECKIN_BRIEFING_OPTIONS)
                    .query();
            }, { root: true });
        }
    },

    getters: {
        getEmoji(state) {
            return (type, briefingOption) => {
                if (state.briefingEmojiMap[type] && state.briefingEmojiMap[type][briefingOption]) {
                    return state.briefingEmojiMap[type][briefingOption];
                }
            };
        }
    }
};