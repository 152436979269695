import axios from 'axios';
import { TRIQ_API_URL } from '../config';

const API_ENDPOINT_SEAN_ELLIS_ANSWER = '/api/v1/app/sean-ellis-answer';
const API_ENDPOINT_SEAN_ELLIS_FEEDBACK = '/api/v1/app/sean-ellis-feedback';


export default {
    namespaced: true,

    actions: {
        getAnswerGuid({ dispatch }, payload) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_SEAN_ELLIS_ANSWER, payload, config);
            }, { root: true });
        },

        sendFeedback({ dispatch }, payload) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_SEAN_ELLIS_FEEDBACK, payload, config);
            }, { root: true });
        }
    }
};